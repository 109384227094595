<template>
    <div>
        <brand-header/>
        <section class="campaign-index">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-1">
                <brand-sidebar/>
              </div>
              <div class="col-md-11" style="margin-top: 20px;">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </section>
        </div>
</template>
<script>
import BrandHeader  from '@/components/Brand/BrandHeader.vue'
import BrandSidebar from '@/components/Brand/BrandSidebar.vue'
import '@/assets/css/styles.css'

export default {
  name: 'BrandLayout',
  data: function () {
    return {
    }
  },
  components: {
    BrandHeader,
    BrandSidebar
  },
}
</script>
