import Vuex from 'vuex'
import axios from 'axios'

export default new Vuex.Store({
  state: initialState(),
  mutations: {
    setTokenToLocalStorage (state, data) {
      localStorage.setItem('tokenData', JSON.stringify(data))
      localStorage.setItem('accessToken', data.access_token)
      let tokenData = localStorage.getItem('tokenData') || ''
      let accessToken = localStorage.getItem('accessToken') || ''
      state.tokenData = JSON.parse(tokenData)
      state.accessToken = accessToken
    },

    setUserDataToLocalStorage (state, data) {
      console.log(data)
      localStorage.setItem('userData', JSON.stringify(data))
      state.userData = initialState().userData
    },

    setBrandAccessToken (state, data) {
      localStorage.setItem('brandAccessToken', data.access_token)
      let accessToken = localStorage.getItem('brandAccessToken') || ''
      state.brandAccessToken = accessToken
    },

    setLoginAsBrand (state, data) {
      localStorage.setItem('loginAsBrand', data)
      state.loginAsBrand = data
    },

    setBrandOptions (state, data) {
      state.brandOptions = data
    },

    setBrandsList (state, data) {
      for (var key in Object.keys(data.options)) {
        if (data.options[key] !== state.brandOptions[key]) {
          return
        }
      }
      // all options match
      if (data.clear) state.brands = initialState().brands
      state.brands.data.push(...data.data.data)
      state.brands.count = data.data.count
    },

    clearUserRole (state) {
      state.userData.role = ''
    },

    loadUserData (state) {
      state.userData = initialState().userData
    },

    showMessage (state, { message, duration }) {
      state.message = message
      if (duration === undefined || duration === null) {
        state.messageDuration = initialState().messageDuration
      } else state.messageDuration = duration
      state.showMessage = true

      if (state.messageDuration > 0) {
        setTimeout(() => (state.showMessage = false), state.messageDuration)
      }
    },

    hideMessage (state) {
      state.showMessage = false
      state.message = ''
    },

    showLoading (state, message = null) {
      if (message) {
        state.message = message
      } else {
        state.message = 'Loading...'
      }
      state.messageDuration = 0
      state.showMessage = true
    },

    setBrandCredits (state, credits) {
      state.brandCreditsRemaining = credits
    }
  },
  getters: {
    isBrandListComplete (state) {
      return state.brands.count === state.brands.data.length
    }
  },
  actions: {
    getBrandsList ({ state, commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        let options = {
          query:
            payload.query === undefined
              ? state.brandOptions.query
              : payload.query,
          limit:
            payload.limit === undefined
              ? state.brandOptions.limit
              : payload.limit,
          offset:
            payload.offset === undefined
              ? state.brandOptions.offset
              : payload.offset,
          sort:
          payload.sort === undefined
            ? state.brandOptions.sort
            : payload.sort,
          status:
            payload.status === undefined
              ? ''
              : payload.status
        }
        let clear = payload.clear === undefined ? true : payload.clear
        commit('setBrandOptions', options)
        axios
          .get('brands?' + options, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => {
              commit('setBrandsList', {
                options: options,
                data: response.body,
                clear: clear
              })
              resolve(response)
            },
            (response) => {
              // show error dialog
              reject(response)
            }
          )
      })
    },

    addBrand (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('brands', payload)
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },

    addEmedToUnsubscribersList (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('unsubscribe-email', payload)
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },
    addTask (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('tasks', payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },
    addTaskDataPoint (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + '/task_data_points', payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },
    addBrandTutorialVideo (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + '/brand_videos_tutorials', payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },

    editTask (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`tasks/${payload.id}`, payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },
    updateTaskDataPoint (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + `/task_data_points/${payload.id}`, payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },

    updateBrandTutorialVideo (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + `/brand_video_tutorials/${payload.id}`, payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },

    editBrand (context, payload) {
      return new Promise((resolve, reject) => {
        if (!payload || !payload.id || !payload.action) reject(payload)
        axios.patch(`brands/${payload.id}/${payload.action}`).then(
          (response) => {
            resolve(response)
          },
          (response) => {
            reject(response)
          }
        )
      })
    },

    getBrand (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`brands/${payload}?with=subscription_history+pickl_history+invoices`)
          .then(
            (response) => {
              let brand = response.body.data
              if (!brand.logo || brand.logo.trim().length === 0) {
                // brand.logo = Vue.common.getDefaultImage('brand')
              }
              if (brand.subscription_history.length > 0) {
                let subscriptions = brand.subscription_history
                let subscriptionsFormatted = {}
                subscriptions.forEach((x) => {
                  let date = new Date(x.created_at)
                  let key = date.getFullYear() * 100 + date.getMonth()
                  if (subscriptionsFormatted[key] === undefined) {
                    subscriptionsFormatted[key] = []
                  }
                  subscriptionsFormatted[key].push(x)
                })
                brand.subscription_history = []
                for (var key in subscriptionsFormatted) {
                  brand.subscription_history.push({
                    key: key,
                    value: subscriptionsFormatted[key]
                  })
                }
                brand.subscription_history.sort((a, b) => {
                  return b['key'] - a['key']
                })
              }
              if (brand.pickl_history.length > 0) {
                let ph = brand.pickl_history
                let phFormatted = {}
                ph.forEach((x) => {
                  let date = new Date(x.created_at)
                  let k = date.getFullYear() * 100 + date.getMonth()
                  if (phFormatted[k] === undefined) {
                    phFormatted[k] = []
                  }
                  phFormatted[k].push(x)
                })
                brand.pickl_history = []
                for (var k in phFormatted) {
                  brand.pickl_history.push({
                    key: k,
                    value: phFormatted[k]
                  })
                }
                brand.pickl_history.sort((a, b) => {
                  return b['key'] - a['key']
                })
              }
              if (brand.invoices.length > 0) {
                let invoices = brand.invoices
                let invoicesFormatted = {}
                invoices.forEach((x) => {
                  let date = new Date(x.updated_at)
                  let invoiceKey = date.getFullYear() * 100 + date.getMonth()
                  if (invoicesFormatted[invoiceKey] === undefined) {
                    invoicesFormatted[invoiceKey] = []
                  }
                  invoicesFormatted[invoiceKey].push(x)
                })
                brand.invoices = []
                for (var invoiceKey in invoicesFormatted) {
                  brand.invoices.push({
                    key: invoiceKey,
                    value: invoicesFormatted[invoiceKey]
                  })
                }
                brand.invoices.sort((a, b) => {
                  return b['key'] - a['key']
                })
              }
              response.body.data = brand
              resolve(response.body.data)
            },
            (response) => reject(response)
          )
      })
    },

    updatePassword ( { id, old, newp, confirm }) {
      return new Promise((resolve, reject) => {
        let payload = {
          current_password: old,
          new_password: newp,
          confirm_password: confirm
        }
        axios.put(`users/${id}/password`, payload).then(
          (response) => {
            resolve(response)
          },
          (response) => {
            reject(response)
          }
        )
      })
    },

    getAppSettings () {
      return axios.get('settings')
    },
    getRewardPoints () {
      return axios.get('reward_points')
    },

    setAppSettings (context, payload) {
      return axios.put('settings', { settings: payload })
    },

    getUserData ({ state, commit, dispatch }, userType = null) {
      return new Promise((resolve, reject) => {
        if (userType !== null) {
          axios.get('token-user', {
            'headers': {
              'user-type': userType
            }
          }).then(
            (response) => {
              console.log(response);
              commit('setUserDataToLocalStorage', response.data)
              if (state.userData.role === 'brand' || state.userData.role === 'brand') {
                dispatch('getBrandCurrentSubscription', state.userData.brandId)
              }
              resolve(response)
            },
            (response) => reject(response)
          )
        } else {
          axios.get('token-user').then(
            (response) => {
              console.log(response)
              commit('setUserDataToLocalStorage', response.data)
              if (state.userData.role === 'brand' || state.userData.role === 'brand') {
                dispatch('getBrandCurrentSubscription', state.userData.brandId)
              }
              resolve(response)
            },
            (response) => reject(response)
          )
        }
      })
    },

    updateProfile ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`users/${payload.id}`, payload.user).then(
          (response) => {
            dispatch('getUserData')
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },
    updateNonGeoPicklStatus ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-non-geo-pickl-status`, payload).then(
          (response) => {
            dispatch('getUserData')
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },
    updateGeoPicklStatus ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-geo-pickl-status`, payload).then(
          (response) => {
            dispatch('getUserData')
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },
    updateOnBoardStatus ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-onboard-status`, payload).then(
          (response) => {
            dispatch('getUserData')
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },

    updateBrandAgeRestrictedStatus ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-age-restricted-status`, payload).then(
          (response) => {
            dispatch('getUserData')
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },
    updateBrandSurveyFeatureStatus (payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-survey-feature-status`, payload).then(
          (response) => {
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },
    updateBrandDashboardDataAccess ( payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-dashboard-data-access`, payload).then(
          (response) => {
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },
    updateBrandDemographicsFiltersFeatureStatus ( payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-demographics-filters-feature-status`, payload).then(
          (response) => {
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },

    updateBrandCouponFeatureStatus (payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-coupon-feature-status`, payload).then(
          (response) => {
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },
    updateBrandDataVisualFeatureStatus (payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-data-visual-feature-status`, payload).then(
          (response) => {
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },

    updateBrandSelfieFeatureStatus ( payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-selfie-feature-status`, payload).then(
          (response) => {
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },
    updateBrandReimbursementFeatureStatus ( payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-reimbursement-feature-status`, payload).then(
          (response) => {
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },
    updateBrandLockPicklFeatureStatus (payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-lock-pickl-feature-status`, payload).then(
          (response) => {
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },
    updateBrandMultiTaskFeatureStatus ( payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/update-multi-task-status`, payload).then(
          (response) => {
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },

    updateUserImage ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`users/${payload.id}/image`, payload.user).then(
          (response) => {
            dispatch('getUserData')
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },

    updateBrandImage ( payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`brands/${payload.id}/image`, payload.user).then(
          (response) => {
            resolve(response)
          },
          (response) => reject(response)
        )
      })
    },

    logout ({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.loginAsBrand) {
          // Vue.notify({ type: 'error', text: 'Logout as brand first!' })
          reject(new Error('Logout as brand first!'))
          return
        }
        commit('clearUserRole')
        axios.post('logout').then(
          (response) => {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('tokenData')
            localStorage.removeItem('userData')
            resolve(response)
          },
          (response) => {
            commit('loadUserData')
            reject(response)
          }
        )
      })
    },

    addFreePickls (context, payload) {
      return axios.put(`brands/${payload.id}/pickls`, payload.data)
    },

    updateBrandStripeFee (context, payload) {
      return axios.put(`brands/${payload.id}/stripe-fee`, payload.data)
    },
    updateBrandMaxPicklHoldLimit (context, payload) {
      return axios.put(`brands/${payload.id}/max-pickl-hold-limit`, payload.data)
    },

    sendMsgToPicklrs (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('user_pickls/send_message', payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },

    sendCustomNotificationToPicklrs (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('user_pickls/send_custom_notification', payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },

    sendEmailToPicklrs (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('user_pickls/send_email', payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },

    exportInsightStats (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('pickls/export_survey_report', payload, {
            headers: {
              'user-type': payload.userType
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },

    exportPicklInsights (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('pickls/export_pickl_report', payload, {
            headers: {
              'user-type': payload.userType
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },

    sendNotification (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('user_pickls/send_notification', payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },
    sendCustomMixNotifications (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('user_pickls/send_custom_mix_notification', payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },
    extendPicklExpiration (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('pickls/extend_pickl_expiration', payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },

    getBrandExpiredPickls (context, payload) {
      return axios.get(`brands/${payload.brandId}/pickls/expired?days=${payload.days}`)
    },

    deleteExpiredPickls (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`brands/${payload.brandId}/pickls/expired`, payload, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
      })
    },

    getBrandStatusCount (context, payload) {
      if (typeof payload.campaignId !== 'undefined') {
        return axios.get(`brands/${payload.brandId}/pickls/status-count?campaignId=${payload.campaignId}`)
      } else {
        return axios.get(`brands/${payload.brandId}/pickls/status-count`)
      }
    },

    getNonGeoPicklBrandStatusCount (context, payload) {
      return axios.get(`brands/${payload}/pickls/status-count?type=NON_GEO`)
    },
    getBrandStats (context, brandId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `brands/${brandId}/stats`
          )
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getBrandPickls (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `brands/${payload.id}/pickls`, { params: payload }
          )
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getBrandSubscriptionHistory (state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`brands/${state.userData.brandId}/subscriptions?limit=${payload.limit}&offset=${payload.offset}&query=${payload.query}`)
          .then(
            (response) => {
              let currentCount = response.body.data.length
              if (currentCount > 0) {
                let subscriptions = response.body.data
                let subscriptionsFormatted = {}
                subscriptions.forEach((x) => {
                  let date = new Date(x.created_at)
                  let key = date.getFullYear() * 100 + date.getMonth()
                  if (subscriptionsFormatted[key] === undefined) {
                    subscriptionsFormatted[key] = []
                  }
                  subscriptionsFormatted[key].push(x)
                })
                subscriptions = []
                for (var key in subscriptionsFormatted) {
                  subscriptions.push({
                    key: key,
                    value: subscriptionsFormatted[key]
                  })
                }
                subscriptions.sort((a, b) => {
                  return b['key'] - a['key']
                })
                response.body.data = subscriptions
              }
              resolve({
                currentCount: currentCount,
                response: response,
                options: payload
              })
            },
            (response) => reject(response)
          )
      })
    },

    getProductsList (context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .get(`brands/${payload.brand_id}/products?limit=${payload.limit}&offset=${payload.offset}&query=${payload.query}`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },
    getCampaigns ( payload) {
      return new Promise((resolve, reject) => {
        console.log(payload)
        axios
          .get(`brands/${payload.brand_id}/pickl_campaigns`, { params: payload })
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },
    getSadminCampaigns ( payload) {
      return new Promise((resolve, reject) => {
        console.log(payload)
        axios
          .get(`pickl_campaigns`, { params: payload })
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getBrandLoginHistory ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`brands/${payload.brand_id}/brand_login_logs?limit=${payload.limit}&offset=${payload.offset}`)
          .then(
            (response) => {
              resolve({ options: payload, data: response.data })
            },
            (response) => reject(response)
          )
      })
    },

    addProduct ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`brands/${payload.brandId}/products`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    uploadMediaImages ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/media/${payload.mediaId}/images`, payload
          )
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    uploadCouponImage ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`coupons/${payload.couponId}/image`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    deleteProduct ( payload) {
      return axios.delete(
        `brands/${payload.brandId}/products/${payload.productId}`
      )
    },

    deleteMedia ( payload) {
      return axios.delete(
        process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/media/${payload.mediaId}`, payload
      )
    },
    deleteSurveyQuestion ( payload) {
      return axios.delete(
        `brands/${payload.brandId}/surveys/${payload.urveyId}/questions/${payload.questionId}`
      )
    },

    getStores (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get('stores', { params: payload }).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    getBrands (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`brands?limit=${payload.limit}&offset=${payload.offset}&sort=${payload.sort}`)
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },
    getCountriesList (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`countries?query=${payload.query}&limit=${payload.limit}&offset=${payload.offset}&service_enabled=${payload.service_enabled}`)
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getOffersList (content, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/offers?query=${payload.query}&limit=${payload.limit}&offset=${payload.offset}`)
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getTaskDataPoints () {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/task_data_points`)
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getBrandTutorialVideos (content, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brand_video_tutorials?query=${payload.query}&limit=${payload.limit}&offset=${payload.offset}`)
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getBrandOffersList (content, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/offers`)
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getOffer (content, offerId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/offers/${offerId}`)
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getTasksList (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`tasks?query=${payload.query}&limit=${payload.limit}&offset=${payload.offset}`)
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getBrandProducts (context, payload) {
      let brandId = payload.brandId
      delete payload['brandId']
      return new Promise((resolve, reject) => {
        axios.get(`brands/${brandId}/products`, { params: payload }).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    getTasks (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`tasks?limit=${payload.limit}&offset=${payload.offset}`)
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },
    getAppSettingsKeyValuePair (context) {
      return new Promise((resolve, reject) => {
        axios.get(`settings-key-values`).then(
          (response) => {
            context.state.settings = response.body.data
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    addPickl (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`brands/${payload.brandId}/pickls`, payload.pickl, {
            headers: {
              'user-type': 'brand'
            }
          })
          .then(
            (response) => resolve(response),
            (response) => reject(response)
          )
          .finally(() =>
            context.dispatch(
              'getBrandCurrentSubscription',
              payload.brandId
            )
          )
      })
    },
    loginAsBrand ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`brands/${payload.brandId}/login`).then(
          (response) => {
            commit('setBrandAccessToken', response.body)
            commit('setLoginAsBrand', true)
            dispatch('getUserData').then(
              (user) => resolve(user),
              (user) => reject(user)
            )
          },
          (response) => reject(response)
        )
      })
    },
    logoutAsBrand ({ state, commit, dispatch }) {
      if (state.loginAsBrand) {
        return new Promise((resolve, reject) => {
          commit('clearUserRole')
          axios.post('logout').then(
            () => {
              localStorage.removeItem('brandAccessToken')
              localStorage.removeItem('userData')
              commit('setBrandAccessToken', { accessToken: '' })
              commit('setLoginAsBrand', false)
              dispatch('getUserData').then(
                (user) => resolve(user),
                (user) => reject(user)
              )
            },
            (response) => {
              commit('loadUserData')
              reject(response)
            }
          )
        })
      } else {
        return dispatch('logout')
      }
    },
    getBrandProfile (_context, brandId) {
      return new Promise((resolve, reject) => {
        axios.get(`brands/${brandId}/profile`).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    getNonGeoUserPicklDetails (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`user_pickls/non-geo/${payload.userPicklId}`).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    getUserPicklDetails (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`user_pickls/geo/${payload.userPicklId}`).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    updateBrandProfile (_context, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`brands/${payload.id}`, payload).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    editPicklPack (_context, payload) {
      return axios.put(`subscriptions/${payload.id}`, payload.data)
    },
    resetLocalStorage ({ commit }) {
      return new Promise(() => {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('tokenData')
        localStorage.removeItem('brandAccessToken')
        localStorage.removeItem('loginAsBrand')
        localStorage.removeItem('userData')
        commit('loadUserData')
        // resolve()
      })
    },
    getBrandCurrentSubscription ({ commit }, brandId) {
      return new Promise((resolve, reject) => {
        axios.get(`brands/` + brandId + `/current_subscription`).then(
          (response) => {
            if (
              response.data &&
              response.data.credits !== undefined &&
              response.data.credits !== null
            ) {
              commit('setBrandCredits', response.data.credits)
            }
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    saveClientLog (_context, payload) {
      return axios.post('web-app-errors', payload)
    },
    getProductSections (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get('product_sections', { params: payload }).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    getStoreLocations (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get('store_locations', { params: payload }).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    getBrandPicklHistory ( state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`brands/${state.userData.brandId}/pickl_history?limit=${payload.limit}&offset=${payload.offset}`)
          .then(
            (response) => {
              let currentCount = response.body.data.length
              if (currentCount > 0) {
                let subscriptions = response.body.data
                let subscriptionsFormatted = {}
                subscriptions.forEach((x) => {
                  let date = new Date(x.created_at)
                  let key = date.getFullYear() * 100 + date.getMonth()
                  if (subscriptionsFormatted[key] === undefined) {
                    subscriptionsFormatted[key] = []
                  }
                  subscriptionsFormatted[key].push(x)
                })
                subscriptions = []
                for (var key in subscriptionsFormatted) {
                  subscriptions.push({
                    key: key,
                    value: subscriptionsFormatted[key]
                  })
                }
                subscriptions.sort((a, b) => {
                  return b['key'] - a['key']
                })
                response.body.data = subscriptions
              }
              resolve({
                currentCount: currentCount,
                response: response,
                options: payload
              })
            },
            (response) => reject(response)
          )
      })
    },

    getBrandReimbursementCreditsLog ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`brands/${payload.brandId}/reimbursement-credits-log?limit=${payload.limit}&offset=${payload.offset}`)
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getBrandReimbursementCreditsPurchaseLog ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`brands/${payload.brandId}/reimbursement-credits-purchase-log?limit=${payload.limit}&offset=${payload.offset}`)
          .then(
            (response) => {
              resolve({ response: response })
            },
            (response) => reject(response)
          )
      })
    },
    updateProduct (state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `brands/${state.userData.brandId}/products/${payload.id}`,
            payload
          )
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getSubscriptionHistory (_context, payload) {
      return new Promise((resolve, reject) => {
        axios.get('invoices', { params: payload }).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    restoreLocation (_context, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`store_locations/${payload.id}/restore`).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    toggleCountryStatus (_context, country) {
      return new Promise((resolve, reject) => {
        axios.put(`countries/${country.id}`, country).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    restoreStore (_context, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`stores/${payload.id}/restore`).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    getBrandInvoiceHistory (state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`brands/${state.userData.brandId}/invoices?limit=${payload.limit}&offset=${payload.offset}&query=${payload.query}`)
          .then(
            (response) => {
              let currentCount = response.body.data.length
              if (currentCount > 0) {
                let subscriptions = response.body.data
                let subscriptionsFormatted = {}
                subscriptions.forEach((x) => {
                  let date = new Date(x.created_at)
                  let key = date.getFullYear() * 100 + date.getMonth()
                  if (subscriptionsFormatted[key] === undefined) {
                    subscriptionsFormatted[key] = []
                  }
                  subscriptionsFormatted[key].push(x)
                })
                subscriptions = []
                for (var key in subscriptionsFormatted) {
                  subscriptions.push({
                    key: key,
                    value: subscriptionsFormatted[key]
                  })
                }
                subscriptions.sort((a, b) => {
                  return b['key'] - a['key']
                })
                response.body.data = subscriptions
              }
              resolve({
                currentCount: currentCount,
                response: response,
                options: payload
              })
            },
            (response) => reject(response)
          )
      })
    },
    getProductImage (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`product_image/${payload.productId}/${payload.imageId}`).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    getSurveysList ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brand_id}/surveys?size=${payload.limit}&offset=${payload.offset}&query=${payload.query}&type=${payload.type}`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getBrandCoupons ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/coupons/brand?limit=${payload.limit}&offset=${payload.offset}&search=${payload.query}&type=PICKL&brandId=${payload.brandId}`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },
    getGlobalCoupons ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/coupons/global?size=${payload.limit}&offset=${payload.offset}&query=${payload.query}&type=${payload.type}&brandId=${payload.brand_id}`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getMediaList ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brand_id}/media?limit=${payload.limit}&offset=${payload.offset}`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getLocationCatalogueList ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brand_id}/location_catalogues?size=${payload.limit}&offset=${payload.offset}&query=${payload.query}`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },
    getClusterLocations ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brand_id}/location_catalogue/store_locations?type=${payload.type}&location_catalogue_ids=${payload.ids}`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },

    createStoreCluster ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/location_catalogue/create`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    getSurveyById ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/surveys/${payload.surveyId}`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getCouponById ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/coupons/${payload.couponId}`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getLocationCatalogueById ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/location_catalogues/${payload.locationCatalogueId}`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },

    getCatalogueStoreLocations ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/location_catalogues/${payload.locationCatalogueId}/locations`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },
    getCouponStates ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/coupon_states/${payload.couponId}`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },
    getStateCities ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/states/${payload.stateId}/cities`)
          .then(
            (response) => {
              resolve({ options: payload, response: response })
            },
            (response) => reject(response)
          )
      })
    },
    saveSurvey ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/surveys`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    saveMedia ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/media`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    saveCoupon ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/coupons`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    saveBulkCouponStates ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/coupon_states/bulk`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    updateCoupon ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + `/coupons/${payload.id}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    updateMedia ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/media/${payload.id}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    saveLocationCatalogue ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/location_catalogues`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    upadtedLocationCatalogue ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brand_id}/location_catalogues/${payload.id}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    updateSurvey ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/surveys/${payload.id}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    saveQuestion ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/surveys/${payload.surveyId}/questions`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    updateQuestion ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/surveys/${payload.surveyId}/questions/${payload.id}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    saveAnswer ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/surveys/${payload.surveyId}/questions/${payload.questionId}/answers`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    updateAnswer ( payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/surveys/${payload.surveyId}/questions/${payload.questionId}/answers/${payload.id}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    deleteSurvey ( payload) {
      return axios.delete(
        process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/surveys/${payload.surveyId}`, payload
      )
    },

    deleteCoupon ( payload) {
      return axios.delete(
        process.env.VUE_APP_API_BASE_URL + `/coupons/${payload.couponId}`, payload
      )
    },
    deleteCouponState ( payload) {
      return axios.delete(
        process.env.VUE_APP_API_BASE_URL + `/coupon_states/${payload.id}`, payload
      )
    },

    deleteLocationCatalogue ( payload) {
      return axios.delete(
        process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/location_catalogues/${payload.catalogueId}`, payload
      )
    },
    deleteCatalogueStoreLocation ( payload) {
      return axios.delete(
        process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/location_catalogues/${payload.locationCatalogueId}/locations/${payload.catalogueStoreLocationId}`, payload
      )
    },

    deleteOffer (payload) {
      return axios.delete(
        process.env.VUE_APP_API_BASE_URL + `/offers/${payload.offerId}`, payload
      )
    },

    deleteBrandOffer ( payload) {
      return axios.delete(
        process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/offers/${payload.brandOfferId}`, payload
      )
    },

    getPicklDetails (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`pickls/${payload.picklId}`).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },

    getNonGeoPicklDetails (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`pickls/non-geo/${payload.picklId}`).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    getMultipleResponsePicklDetails (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`pickls/multiple-responses/${payload.picklId}`).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },

    cancelBrandSubscription (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`brands/${payload.brandId}/subscription/cancel`, payload).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    addLoginLog (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/login-log`, payload).then(
          (response) => {
            resolve({ response: response })
          },
          (response) => reject(response)
        )
      })
    },
    saveOffer (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/offers`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    craeteOfferLink (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API2_BASE_URL + `/offers/${payload.offerId}/link`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    bulkAssignOfferBrands (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/offers/${payload.offerId}/brands`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    bulkAssignLocationCatalogueStoreLocations (content, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/location_catalogues/${payload.locationCatalogueId}/locations`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    updateOffer (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + `/offers/${payload.id}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    assignOfferToBrand (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/offers`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    fetchOfferBySecret (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API2_BASE_URL + `/brands/${payload.brandId}/offers/link`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    sendInvitationLilnk (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/invitations/brands/${payload.brandId}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    updateInvitationStatus (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + `/invitations/${payload.id}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    getBrandInvitationsList (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/invitations/brands/${payload.brandId}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    getEmailInvitationsList (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/invitations/email/${payload.email}`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    getBrandUsersList (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/users`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    getBrandRoles () {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/roles/brand`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    verifyInvitaionSecret (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/invitations/secret/verify`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    reLaunchExpiredTasks (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/pickls/relaunch`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    deleteBrandInvitation (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(process.env.VUE_APP_API_BASE_URL + `/invitations/${payload.invitationId}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    removeUserBrand (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(process.env.VUE_APP_API_BASE_URL + `/user-brands/${payload.id}`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    getUserBrands (context, userId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/users/${userId}/brands`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    switchUserBrand (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/users/${payload.userId}/brands/${payload.brandId}/switch`, payload, {
            headers: {
              'user-type': 'brand'
            }
          })
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    switchUserBrandDashboard (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/users/${payload.userId}/brands/${payload.brandId}/switch-dashboard`, payload, {
            headers: {
              'user-type': 'brand'
            }
          })
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandPicklCompetitions (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ML_BASE_URL + `/competitions?imgSource=` + process.env.VUE_APP_GS_PATH + `${payload.userPicklId}/${payload.image}`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    saveNonGeoPickl (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/pickls/create-non-geo-pickl`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
          .finally(() =>
            context.dispatch(
              'getBrandCurrentSubscription',
              payload.brandId
            )
          )
      })
    },
    saveUserPicklComment (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/user_pickl_comments`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    getUserPicklCommentsByUserPicklId (context, userPicklId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/user_pickl_comments/${userPicklId}`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    updateUserPickl (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + `/users/${payload.userId}/pickls/${payload.id}`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    popPickls (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/pickls/locations/pop`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    relaunchPickls (content, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/pickls/relaunch`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    exportPickls (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/pickls/export`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    // brand report insights

    getBrandProductInsights (context, brandId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${brandId}/reports/productwiselaunches`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandPicklTypeInsights (context, brandId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${brandId}/reports/pickltypeinsights`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandStateInsights (context, brandId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${brandId}/reports/statewiselaunches`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },

    getBrandsurveyInsights (context, brandId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${brandId}/reports/surveyinsights`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandRetailerInsights (context, brandId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${brandId}/reports/retailerwiselaunches`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandShoppingInsights (context, brandId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${brandId}/reports/shoppinginsights`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandPicklInsights (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/reports/picklsinsights?view=${payload.viewby}`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandAverageCompletionTimes (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/reports/getaveragecompletiontimes?type=${payload.type}`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandIssuesInsights (context, brandId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${brandId}/reports/getissuesinsights`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandSocialSelfies (context, brandId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/brands/${brandId}/reports/userselfies`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getCampaignCalendarData (context, brandId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/pickl_campaigns/calendar-data?brandId=${brandId}`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getNonGeoPicklCampaignCalendarData () {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/pickl_campaigns/non-geo-calendar-data`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    sendOtpToVerifyAccountRemoval (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/remove-account-otp`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    verifyOtpAndRemoveAccount (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + `/verify-otp-to-remove-account`, payload)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandImages (context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/pickl-images/${payload.brandId}`, { params: payload })
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandUsedTasks (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/pickl-tasks-list/${payload.brandId}`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getBrandUsedStores (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/pickl-store-list/${payload.brandId}`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    },
    getStates () {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/states?country_id=234`)
          .then(
            (response) => {
              resolve(response)
            },
            (response) => reject(response)
          )
      })
    }
  }
})

function initialState () {
  let userData = localStorage.getItem('userData') || ''
  if (userData === '') userData = null
  else userData = JSON.parse(userData)

  return {
    tokenData: {},
    pageHeading: 'Dashboard',
    accessToken: localStorage.getItem('accessToken') || '',
    userData: userData,
    loginAsBrand: JSON.parse(localStorage.getItem('loginAsBrand') || 'false'),
    brandAccessToken: localStorage.getItem('brandAccessToken') || '',
    brands: {
      status: false,
      data: [],
      count: 0
    },
    brandOptions: {
      limit: 100,
      offset: 0,
      query: '',
      sort: 'created_at-DESC'
    },
    showMessage: false,
    message: '',
    messageDuration: 3000,
    settings: {},
    brandCreditsRemaining: 0
  }
}
// export default store;
