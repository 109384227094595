import BrandLayout from '@/layouts/BrandLayout.vue'
export default {
  path: '/',
  component: BrandLayout,
  redirect: '/brand/dashboard',
  children: [
    {
      path: '/brand/dashboard',
      name: 'brand-dashboard',
      component: () => import('@/views/Brand/DashboardView.vue'),
      meta: {
        name: 'brand-dashboard',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand Dashboard',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/build-your-campaign',
      name: 'build-your-campaign',
      component: () => import('@/views/Brand/BuildYourCampaign.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Build your campaign | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/create-campaign-settings',
      name: 'campaign-settings',
      component: () => import('@/views/Brand/CreateCampaignSettings.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'campaign-settings | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/pickl-history',
      name: 'pickl-history',
      component: () => import('@/views/Brand/PicklHistory.vue'),
      meta: {
        requiresAuth: false,
        title: 'Pickl History | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/geo-location-type',
      name: 'geo-location-type',
      component: () => import('@/views/Brand/GeoLocationType.vue'),
      meta: {
        requiresAuth: false,
        title: 'Geo location type | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/activity-incentives-type',
      name: 'activity-incentives-type',
      component: () => import('@/views/Brand/ActivityIncentivesType.vue'),
      meta: {
        requiresAuth: false,
        title: 'Activity incentives type | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/use-coupon-free-ticket',
      name: 'use-coupon-free-ticket',
      component: () => import('@/views/Brand/UseCouponOrFreeTicket.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Use coupon or free ticket | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/saved-campaign-settings',
      name: 'saved-campaign-settings',
      component: () => import('@/views/Brand/SavedCampaignSettings.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Saved campaign settings | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/where-you-want-to-launch',
      name: 'where-you-want-to-launch',
      component: () => import('@/views/Brand/WhereYouWantToLaunch.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Where you want to launch | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/any-user-location',
      name: 'any-user-location',
      component: () => import('@/views/Brand/AnyUserLocation.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Any user location | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/choose-audience',
      name: 'choose-audience',
      component: () => import('@/views/Brand/ChooseAudience.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Choose Audience | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/activity-creation',
      name: 'activity-creation',
      component: () => import('@/views/Brand/ActivityCreation.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Activity Creation | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/products',
      name: 'brand-products',
      component: () => import('@/views/Brand/ProductsView.vue'),
      meta: {
        name: 'brand-products',
        requiresAuth: true,
        userType: 'brand',
        title: 'Product Management',
        breadcrumb: 'Products Management'
      }
    },
    {
      path: '/brand/question',
      name: 'question',
      component: () => import('@/views/Brand/QuestionCreation.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Question | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/action-links',
      name: 'action-links',
      component: () => import('@/views/Brand/ActionLinks.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Action Links | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/any-physical-location-activity',
      name: 'any-physical-location-activity',
      component: () => import('@/views/Brand/AnyPhysicalLocationActivity.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Any Physical Location Activity | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/do-anywhere-insights',
      name: 'do-anywhere-insights',
      component: () => import('@/views/Brand/DoAnywhereInsights.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Do Any Where Insights | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/new-audience',
      name: 'new-audience',
      component: () => import('@/views/Brand/NewAudience.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'New Audience | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/generate-link-to-share-with-audience',
      name: 'generate-link-to-share-with-audience',
      component: () => import('@/views/Brand/GenerateLinkToShareWithAudience.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        title: 'Generate link to share with audience | Brand',
        breadcrumb: 'Login'
      }
    },
    


  ]
}
