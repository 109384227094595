<template>

    <!-- <div :class="{'sidebar-collapse': isSidebarCollapsed}">
    
        <div class="below-header">
            <div class="sidebar">
                  <div class="sidebar-inner sidebar-mobile">
                      <div class="sidebar-profile" v-if="$store.state.userData">
                          <img class="profile-photo-sidebar" :src="$store.state.userData.image + '?fit=crop&w=150&h=150'" @error="$common.changeDefaultImage($event, 'brand')" />
                          <div class="profile-details">
                              <h4>{{$store.state.userData.brandName}}</h4>
                               <h4><a data-toggle="modal" data-target="#switch-brand-popup" style="cursor:pointer;">Switch Brand</a></h4> 
                          </div>
                      <div class="clear"></div>
                      </div>
                      <ul>
                        <li v-if="$store.state.userData.dataVisualFeature == 1"><router-link to="/brand/reports" @click="$emit('sidebarCollapsed')" class="tasks-cls">Data Visuals</router-link></li>

                        <li><router-link to="/brand/campaigns" @click="$emit('sidebarCollapsed')" class="pickl-cls">Campaigns</router-link></li>

                        <li><router-link to="/brand/campaigns-data" @click="$emit('sidebarCollapsed')" class="pickl-cls">Campaign Data</router-link></li>
                                                  <li><router-link to="/brand/tutorial-videos" @click="$emit('sidebarCollapsed')" class="offers-cls">Tutorials</router-link></li>
                          <li><router-link to="/brand/dashboard" @click="$emit('sidebarCollapsed')" class="home-cls">Home</router-link></li>
                          <li v-if="$store.state.userData.nonGeoPicklFeature == 1"><router-link to="/brand/non-geo-pickl-history" @click="$emit('sidebarCollapsed')" class="pickl-cls">Go-Anywhere</router-link></li>
                          <li><router-link to="/brand/pickl-history" @click="$emit('sidebarCollapsed')" class="pickl-cls">Go-Anywhere</router-link></li>
                          <li><router-link to="/brand/image-gallery" @click="$emit('sidebarCollapsed')" class="pickl-cls">Image Gallery</router-link></li>

                          <li v-if="$store.state.settings.enable_survey_feature == 1"><router-link to="/brand/insights" @click="$emit('sidebarCollapsed')" class="surveys-cls">Consumer Insights</router-link></li>
                          <li><router-link to="/brand/products" @click="$emit('sidebarCollapsed')" class="products-cls">Product Activities</router-link></li>
                          <li v-if="$store.state.userData.nonGeoPicklFeature == 1"><router-link to="/brand/media" @click="$emit('sidebarCollapsed')" class="home-cls">Media Activities</router-link></li>
                          <li v-if="$store.state.userData.couponFeature == 1"><router-link to="/brand/coupons" @click="$emit('sidebarCollapsed')" class="offers-cls">Deal Drops</router-link></li>
                          <li v-if="$store.state.settings.enable_location_catalogue_feature == 1"><router-link to="/brand/location-catalogues" @click="$emit('sidebarCollapsed')" class="catalogues-cls">Store Clusters</router-link></li>
                          <li><router-link to="/brand/offers" @click="$emit('sidebarCollapsed')" class="offers-cls">Promotions</router-link></li>
                      </ul>
                  </div>
                  <div class="sidebar-desktop">
                <VuePerfectScrollbar class="scroll-height">
                  <div class="sidebar-inner">
                      <div class="sidebar-profile" v-if="$store.state.userData">
                        <router-link to="/brand/dashboard">
                          <img class="profile-photo-sidebar" :src="$store.state.userData.image || $common.getDefaultImage('brand')" @error="$common.getDefaultImage('brand')" />
                          <div class="profile-details">
                              <h4>{{$store.state.userData.brandName}}</h4>
                          </div>
                        </router-link>
                      <div class="clear"></div>
                      </div>
                      <ul>
                          <li></li>
                          <li><strong style="font-size: 120%;"> Campaign Activities</strong></li>
                          <li v-if="$store.state.userData.nonGeoPicklFeature == 1"><router-link to="/brand/non-geo-pickl-history" class="pickl-cls">Consumer Located</router-link></li>
                          <li><router-link to="/brand/pickl-history" class="pickl-cls">Physical Location </router-link></li>
                          <li><router-link to="/brand/image-gallery" class="pickl-cls">Images Gallery </router-link></li>
                          <li><router-link to="/brand/tutorial-videos" class="pickl-cls">Tutorials</router-link></li>
                          <li></li>
                          <li><strong style="font-size: 120%;"> Data</strong></li>
                          <li v-if="$store.state.userData.dataVisualFeature == 1"><router-link to="/brand/data-analysis" class="offers-cls">Visuals</router-link></li>
                          <li><router-link to="/brand/campaigns" class="offers-cls">Campaigns</router-link></li>
                          <li><router-link to="/brand/campaigns-data" class="offers-cls">Insights</router-link></li>
                          <li></li>
                          <li><strong style="font-size: 120%;"> Build Campaigns</strong></li>
                          <li v-if="$store.state.userData.nonGeoPicklFeature == 1"><router-link to="/brand/media" class="surveys-cls">Digital Media</router-link></li>
                          <li v-if="$store.state.settings.enable_survey_feature == 1"><router-link to="/brand/insights" class="surveys-cls"> Consumer Questions</router-link></li>
                          <li><router-link to="/brand/products" class="surveys-cls">Activities & Products</router-link></li>
                          <li v-if="$store.state.userData.couponFeature == 1"><router-link to="/brand/coupons" class="surveys-cls">Coupons</router-link></li>
                          <li v-if="$store.state.settings.enable_location_catalogue_feature == 1"><router-link to="/brand/location-catalogues" class="catalogues-cls">Location Clusters</router-link></li>
                          <li></li>
                          <li><router-link to="/brand/offers" class="home-cls">Your Rewards</router-link></li>
                          <div class="clearfix"></div>
                      </ul>
                  </div>
                </VuePerfectScrollbar>
                </div>
            </div>
        </div>
    </div>  -->
        <div class="sidebar">
          <ul>
            <li><a href="#"><img src="/img/sidebar1.png"></a></li>
            <li><a href="#"><img src="/img/sidebar2.png"></a></li>
            <li><a href="#"><img src="/img/sidebar3.png"></a></li>
            <li><a href="/brand/pickl-history"><img src="/img/sidebar4.png"></a></li>
            <li><a href="#"><img src="/img/sidebar5.png"></a></li>
            <li><a href="#"><img src="/img/sidebar6.png"></a></li>
            <li><a href="#"><img src="/img/sidebar1.png"></a></li>
            <li><a href="#"><img src="/img/sidebar7.png"></a></li>
            <li><a href="#"><img src="/img/sidebar8.png"></a></li>
          </ul>
        </div> 
</template>
<script>
import '@/assets/css/sidebar.css'
export default {
  name: 'BrandSidebarComponet',
}
</script>
